import { render, staticRenderFns } from "./VegaMessage.vue?vue&type=template&id=74e21cbc&"
import script from "./VegaMessage.vue?vue&type=script&lang=js&"
export * from "./VegaMessage.vue?vue&type=script&lang=js&"
import style0 from "./VegaMessage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ebinkaniyadan/Documents/Projects/pathadvice-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74e21cbc')) {
      api.createRecord('74e21cbc', component.options)
    } else {
      api.reload('74e21cbc', component.options)
    }
    module.hot.accept("./VegaMessage.vue?vue&type=template&id=74e21cbc&", function () {
      api.rerender('74e21cbc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/upgrade-messages/VegaMessage.vue"
export default component.exports