import { render, staticRenderFns } from "./ScreenSharing.vue?vue&type=template&id=04a000be&scoped=true&"
import script from "./ScreenSharing.vue?vue&type=script&lang=js&"
export * from "./ScreenSharing.vue?vue&type=script&lang=js&"
import style0 from "./ScreenSharing.vue?vue&type=style&index=0&id=04a000be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a000be",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ebinkaniyadan/Documents/Projects/pathadvice-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04a000be')) {
      api.createRecord('04a000be', component.options)
    } else {
      api.reload('04a000be', component.options)
    }
    module.hot.accept("./ScreenSharing.vue?vue&type=template&id=04a000be&scoped=true&", function () {
      api.rerender('04a000be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/incoming/ScreenSharing.vue"
export default component.exports