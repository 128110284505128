<template>
  <div class="chart-info summary-info w-full relative">
    <vx-card v-if="!isLoading" class="summary-info__card">
      <div class="summary-info__data">
        <ul class="summary-info__data__list">
          <!-- TODO Remove comment on Dec, 2024 -->
          <!-- <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px" :text="'Total number of all visiting within the selected date range'" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[0]) }}</div>
            <div class="summary-info__data__list__item__value">{{ totalVisitors }}</div>
          </li> -->
          <!-- <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px;" :text="'Total number of individuals visiting within the selected date range'" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[1]) }}</div>
            <div class="summary-info__data__list__item__value">{{ uniqueVisitors }}</div>
          </li> -->

          <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px" :text="$t('views.home.totalNumberOfVegaVisitingWithinTheSelectedDateRange')" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[2]) }}</div>
            <div class="summary-info__data__list__item__value">{{ sumTotalVegaVisitorsCountPerDay | formatterDot(false) }}</div>
          </li>

          <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px" :text="$t('views.home.numberOfVisitorsWhoShowedStrongBuyingIntent')" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[3]) }}</div>
            <div class="summary-info__data__list__item__value">{{ sumThresholdVegaVisitorsCountPerDay | formatterDot(false) }}</div>
          </li>

          <li class="summary-info__data__list__item">
            <vx-tooltip style="width: 17px; margin: 5px 10px 0px 0px" :text="$t('views.home.shareOfVisitorsWhoShowedStrongBuyingIntent')" position="top">
              <img width="17" height="17" :src="infoImg" />
            </vx-tooltip>

            <div class="summary-info__data__list__item__label">{{ $t(demoSummaryDataLabels[4]) }}</div>
            <div class="summary-info__data__list__item__value">{{ percentVegaVisitorsCountPerDay }}</div>
          </li>
        </ul>

        <!-- CHART -->
        <div class="summary-info__data__chart">
          <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <a class="a-icon summary-info__data__chart__view-label" href="#">
                {{ displayedActiveChartViewRangeOnAnalyticsSummary.text }}

                <vs-icon icon="expand_more"></vs-icon>
              </a>

              <vs-dropdown-menu>
                <vs-dropdown-item v-for="item in chartViewRangeList" :key="item.key" @click="setActiveChartViewRange(item)">
                  {{ item.text }}
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <static-chart-spline :series="totalVegaVisitorsCountPerDay" :xaxis="xaxisValues" :color="color" />
        </div>
      </div>

      <ul class="summary-info__footer-data-list">
        <li class="summary-info__footer-data-list__item">
          <div class="summary-info__footer-data-list__item__label" v-html="$t('views.home.yourAverageSale')"></div>

          <div class="summary-info__footer-data-list__item__value summary-info__footer-data-list__item__value--sales">
            <vs-input
              class="w-full summary-info__footer-data-list__item__input"
              v-model="averageSale"
              name="averageSale"
              type="number"
              v-validate="'required|numeric|min_value:0'"
            />
            <span class="ml-2">€</span>
          </div>
        </li>

        <li class="summary-info__footer-data-list__item">
          <div class="summary-info__footer-data-list__item__label summary-info__footer-data-list__item__label--margin" v-html="$t('views.home.salesPotential')"></div>

          <div class="summary-info__footer-data-list__item__value summary-info__footer-data-list__item__value--sales">
            {{ salesPotential | formatterDot }} <span class="ml-2">€</span>
          </div>
        </li>
      </ul>
    </vx-card>

    <placeholder-text-small v-else />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { Validator } from 'vee-validate'
import validationDictionary from '@/validations/validationDictionary'

import { analyticsData } from './analyticsData'
import StaticChartSpline from '@/components/static-chart-spline/StaticChartSpline.vue'
import PlaceholderTextSmall from '@/components/placeholder-text-small/PlaceholderTextSmall.vue'

export default {
  components: {
    StaticChartSpline,
    PlaceholderTextSmall
  },

  mixins: [analyticsData],

  props: {
    isLoading: {
      type: Boolean
    },
    series: {
      type: Array
    },
    seriesShowCountPerDay: {
      type: Array
    },
    weekdays: {
      type: Array
    }
  },

  data() {
    return {
      averageSale: '',
      demoSummaryDataLabels: ['Total Visitors', 'Unique Visitors', 'Monitored Visitors (Unique)', 'High Potential Visitors', 'Share of untapped Potential'],
      demoSummaryData: [
        {
          value: '125.000'
        },
        {
          value: '123.000'
        },
        {
          value: '125.000'
        },
        {
          value: '23.000'
        },
        {
          value: '18,7%'
        }
      ],
      infoImg: require('@/assets/images/elements/info-light.svg'),
      color: '#f1a342',
      chartViewRangeList: [
        {
          key: 'day',
          text: 'Days'
        },
        {
          key: 'week',
          text: 'Week'
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      activeChartViewRangeOnAnalyticsSummary: 'dashboard/activeChartViewRangeOnAnalyticsSummary',
    }),

    isDayView() {
      return this.activeChartViewRangeOnAnalyticsSummary === 'day'
    },

    salesPotential() {
      if (!this.averageSale || !this.sumThresholdVegaVisitorsCountPerDay) {
        return 0
      }

      const modifiedValue = this.sumThresholdVegaVisitorsCountPerDay * 0.02 * this.averageSale

      return modifiedValue
    },
    totalVegaVisitorsCountPerDay() {
      let totalData = this.series[0].totalData || []
      let thresholdData = this.series[0].thresholdData || []

      if (this.isDayView && totalData[0] && totalData[0].date) {
        totalData = this.series[0].totalData.map((item) => item.unique_visitor_count)

        thresholdData = this.series[0].totalData.map((totalDataItem) => {
          const thresholdDataItem = this.series[0].thresholdData.find((thresholdDataItem) => thresholdDataItem.date.value === totalDataItem.date.value)

          return thresholdDataItem ? thresholdDataItem.unique_visitor_count : 0
        })
      }

      return [
        // TODO Remove comment on Dec, 2024
        // {
        //   name: 'Total Visitors',
        //   data: this.seriesShowCountPerDay[0].data
        // },
        {
          name: 'Monitored Visitors (Unique)',
          data: totalData
        },
        {
          name: 'High Potential Visitors',
          data: thresholdData
        }
      ]
    },

    sumTotalVegaVisitorsCountPerDay() {
      return this.totalVegaVisitorsCountPerDay[0].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    },

    sumThresholdVegaVisitorsCountPerDay() {
      return this.totalVegaVisitorsCountPerDay[1].data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    },

    percentVegaVisitorsCountPerDay() {
      let percent = Math.round((this.sumThresholdVegaVisitorsCountPerDay / this.sumTotalVegaVisitorsCountPerDay) * 100)

      if (isNaN(percent)) {
        percent = 0
      }

      percent = `${percent}%`

      return percent
    },

    summaryData() {
      if (!this.isVegaActivated) {
        return this.demoSummaryData
      }

      const summaryData = this.demoSummaryData

      const totalAllWebsiteVisitorsCount = 0
      let totalWebsiteVisitorsCount = 0
      const uniqueWebsiteVisitorsCount = 0
      let totalVisitorsWithBuyingIntentCount = 0
      let visitorsWithBuyingIntentInPercent = 0

      if (!summaryData || !(summaryData.length > 0)) {
        summaryData[0].value = totalAllWebsiteVisitorsCount
        summaryData[1].value = uniqueWebsiteVisitorsCount
        summaryData[2].value = totalWebsiteVisitorsCount
        summaryData[3].value = totalVisitorsWithBuyingIntentCount
        summaryData[4].value = visitorsWithBuyingIntentInPercent

        return summaryData
      }

      if (this.vegaVisitorAnalytics) {
        totalWebsiteVisitorsCount = this.vegaVisitorAnalytics.reduce((accumulator, currentValue) => accumulator + currentValue.visitor_count, 0)

        const totalVisitorsWithBuyingIntentArray = this.vegaVisitorAnalytics.filter((item) => this.selectedVegaPopupThreshhold.includes(item.score_category))
        totalVisitorsWithBuyingIntentCount = totalVisitorsWithBuyingIntentArray.reduce(
          (accumulator, currentValue) => accumulator + currentValue.visitor_count,
          0
        )
        visitorsWithBuyingIntentInPercent = Math.round((totalVisitorsWithBuyingIntentCount / totalWebsiteVisitorsCount) * 100)
        if (isNaN(visitorsWithBuyingIntentInPercent)) {
          visitorsWithBuyingIntentInPercent = 0
        }
        visitorsWithBuyingIntentInPercent = `${visitorsWithBuyingIntentInPercent}%`
      }

      summaryData[1].value = this.totalVisitors
      summaryData[2].value = this.uniqueVisitors
      summaryData[3].value = totalWebsiteVisitorsCount
      summaryData[4].value = totalVisitorsWithBuyingIntentCount
      summaryData[5].value = visitorsWithBuyingIntentInPercent

      return summaryData
    },
    xaxisValues() {
      let categories = this.weekdays

      if (this.isDayView) {
        categories = this.series[0].totalData.map((item) => {
          return item.date ? item.date.value : ''
        })
      }

      return {
        categories
      }
    },
    title() {
      return this.$i18n.t('views.home.customerReqeuestsCountPerDay')
    },

    displayedActiveChartViewRangeOnAnalyticsSummary() {
      if (this.activeChartViewRangeOnAnalyticsSummary) {
        const value = this.chartViewRangeList.find((item) => item.key === this.activeChartViewRangeOnAnalyticsSummary)

        if (value && value.text) {
          return value
        }
      }

      return this.chartViewRangeList[1]
    }
  },

  methods: {
    ...mapActions({
      updateActiveChartViewRangeOnAnalyticsSummary: 'dashboard/updateActiveChartViewRangeOnAnalyticsSummary',
    }),

    async setActiveChartViewRange(value) {
      if (value.key === this.activeChartViewRangeOnAnalyticsSummary) {
        return
      }

      await this.updateActiveChartViewRangeOnAnalyticsSummary(value.key)

      this.$emit('activeViewChanged')
    }
  },

  mounted() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
  },

  filters: {
    formatterDot(value, isDots = true) {
      const formatterDot = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: isDots ? 2 : 0,
        maximumFractionDigits: isDots ? 2 : 0
      })

      return formatterDot.format(value)
    }
  }
}
</script>
<style lang="scss">
.apexcharts-datalabel-label {
  font-family: 'Lato' !important;
  font-weight: 600 !important;
}

.summary-info {
  &__data__chart {
    &__view-label {
      display: flex;
      margin-right: 10px;
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 500;

      .vs-icon {
        font-size: 20px !important;
      }
    }
  }

  &__footer-data-list {
    position: relative;
    display: flex;
    margin-top: 15px;
    padding: 30px 27px 0px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      padding: 30px 0px 0px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 27px;
      width: calc(100% - 54px);
      height: 2px;
      background: rgb(255, 255, 255);

      @media (max-width: 768px) {
        left: 0;
        width: 100%;
      }
    }

    &__item {
      display: flex;
      width: 47%;

      @media (max-width: 768px) {
        width: 100%;
      }

      &:first-child {
        @media (max-width: 768px) {
          flex-wrap: wrap;

          .summary-info__footer-data-list__item__label {
            margin-bottom: 10px;
            width: 100%;
          }
        }
      }

      &:last-child {
        margin-left: auto;

        @media (max-width: 768px) {
          margin-top: 14px;
        }
      }

      &__label {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: 700;

        @media (max-width: 768px) {
          font-size: 16px;
        }

        &--margin {
          max-width: 180px;
        }
      }

      &__value {
        margin-left: 60px;
        min-width: 40px;
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: 500;

        &--sales {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-align: right;
          font-size: 26px;
          font-weight: 700;
          margin-left: 90px;

          .summary-info__footer-data-list__item {
            &__input {
              margin-left: 0;
            }
          }
        }
      }

      &__input {
        min-width: 125px;

        .vs-input--input.normal {
          width: 125px;
          height: 45px;
          color: rgb(38, 38, 41);
          font-size: 20px !important;
          font-weight: 500;
          direction: rtl;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.chart-info {
  background: #fff;
  border-radius: 6px;
}

.summary-info {
  &__card {
    background: rgb(39, 93, 115);
  }

  &__data {
    display: flex;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    &__list {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px 80px 70px 0;
      min-width: 35%;

      @media (max-width: 768px) {
        padding: 20px 0 0;
        width: 100%;
      }

      &__item {
        display: flex;
        margin-top: 50px;

        @media (max-width: 768px) {
          margin-top: 30px;
        }

        &:first-of-type {
          margin-top: 0;
        }

        // &:nth-of-type(3) {
          // margin-top: 90px;

          // @media (max-width: 768px) {
            // margin-top: 50px;
          // }
        // }

        &__label {
          flex: 1;
          color: rgb(255, 255, 255);
          font-size: 20px;
          font-weight: 700;

          &--margin {
            margin-left: 27px;
          }
        }

        &__value {
          margin-left: 60px;
          min-width: 40px;
          color: rgb(255, 255, 255);
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    &__chart {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      flex-grow: 1;

      @media (max-width: 768px) {
        margin-bottom: 0;
        padding-top: 20px;
        width: 100%;
      }

      &__view-label {
        display: flex;
        margin-right: 10px;
        color: rgb(255, 255, 255);
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
</style>
