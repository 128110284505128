import { render, staticRenderFns } from "./VisitorChats.vue?vue&type=template&id=702fbd98&scoped=true&"
import script from "./VisitorChats.vue?vue&type=script&lang=js&"
export * from "./VisitorChats.vue?vue&type=script&lang=js&"
import style0 from "./VisitorChats.vue?vue&type=style&index=0&id=702fbd98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702fbd98",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ebinkaniyadan/Documents/Projects/pathadvice-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('702fbd98')) {
      api.createRecord('702fbd98', component.options)
    } else {
      api.reload('702fbd98', component.options)
    }
    module.hot.accept("./VisitorChats.vue?vue&type=template&id=702fbd98&scoped=true&", function () {
      api.rerender('702fbd98', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/meeting/call-settings/VisitorChats.vue"
export default component.exports