import { render, staticRenderFns } from "./UserGroupSidebar.vue?vue&type=template&id=ec0ebed0&scoped=true&"
import script from "./UserGroupSidebar.vue?vue&type=script&lang=js&"
export * from "./UserGroupSidebar.vue?vue&type=script&lang=js&"
import style0 from "./UserGroupSidebar.vue?vue&type=style&index=0&id=ec0ebed0&lang=scss&scoped=true&"
import style1 from "./UserGroupSidebar.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec0ebed0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ebinkaniyadan/Documents/Projects/pathadvice-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ec0ebed0')) {
      api.createRecord('ec0ebed0', component.options)
    } else {
      api.reload('ec0ebed0', component.options)
    }
    module.hot.accept("./UserGroupSidebar.vue?vue&type=template&id=ec0ebed0&scoped=true&", function () {
      api.rerender('ec0ebed0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/company/user-groups/UserGroupSidebar.vue"
export default component.exports